<template>
    <div class="px-3 md:px-0 md:-mt-3">
        <loading-modal :isShow="isLoadingQuiz"/>
        <div v-if="!isLoadingQuiz">
            <div class="fixed right-0 left-0 md:relative">
                <div class="header w-full h-24 bg-blue-600 rounded-3xl rounded-t-none px-3 pt-5"></div>
                <div class="hero px-4 -mt-11">
                    <div class="bg-white w-full rounded-2xl p-3 shadow-lg flex flex-col justify-center">
                        <img :src="user.avatar" class="w-20 h-20 mx-auto mt-2 rounded-full" />
                        <h2 class="text-center text-xl font-semibold mt-2 text-gray-800 uppercase truncate overflow-ellipsis overflow-hidden">{{ user.nama_lengkap }}</h2>
                        <h4 class="text-yellow-600 text-xl text-center font-semibold mt-2">Peringkat {{ peringkat.peringkat }} dari {{ peringkat.total }}</h4>
                    </div>
                </div>
            </div>
            <div class="h-64 md:h-4"></div>
            <div class="px-1 md:px-4 grid grid-cols-1 divide-y divide-gray-200">
                <div class="" v-for="(data, index) in peringkat_list" :key="index">
                    <div class="px-3 py-4 flex items-center">
                        <div class="w-16 h-16 text-center items-center flex mr-3 flex-none" v-if="index < 3">
                            <img :src="require(`@/assets/images/winning-${(index + 1)}.svg`)"/>
                        </div>
                        <div class="flex-none w-16 h-16 rounded-full bg-gray-100 text-gray-800 font-semibold text-center items-center flex mr-3 text-3xl" v-else>
                            <div class="mx-auto">{{ index + 1 }}</div>
                        </div>

                        <img :src="data.avatar" class="w-14 h-14 rounded-full mr-2 hidden md:block"/>
                        <div class="flex-wrap flex-grow">
                            <h3 class="font-semibold">{{ data.nama_lengkap }}</h3>
                            <h3>Nilai teringgi <span class="font-semibold">{{ Number(data.nilai_tertinggi) }}</span></h3>
                        </div>
                    </div>
                </div>
            </div>

        </div>

   </div>
</template>
<script>
    import LoadingModal from '../../components/LoadingModal.vue';
    import { errorHandler, showErrorNotif } from '../../helpers/Tools';

    export default {
        name: 'PeringkatDetail',
        components: { LoadingModal,
        },
        data(){
            return {
                isLoading     : true,
                isLoadingQuiz : true,
                page          : 1,
                user          : null,
                peringkat_list: [],
                peringkat     : {},
            }
        },
        mounted() {
			this.getDaftarPeringkat();
		},
		methods : {

            /**
             * 
             */
            async getDaftarPeringkat() {
                this.isLoadingQuiz = true;
                try {
                    let res = await this.$store.dispatch("getPeringkatKuis", {
                        id_kuis : this.$route.params.id_kuis
                    })
                    let result = res.data
                    this.isLoadingQuiz = false;
                    if(result.status == 'success') {
                        this.peringkat_list = result.data.peringkat_list.data;
                        this.peringkat      = result.data.peringkat;
                        this.user           = result.data.user;
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingQuiz = false;
                    errorHandler(this, error);
                }
            },

        }
    }
</script>
